module.exports = [{
      plugin: require('../node_modules/exo-plugin-image/exo-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/exo-plugin-manifest/exo-browser.js'),
      options: {"plugins":[],"name":"Auto Genius","icon":"src/images/icon.png","short_name":"Auto Genius","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8cdf8d251b0e6b4d7c929153fb67d338"},
    },{
      plugin: require('../node_modules/exo-omni-font-loader/exo-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"interval":400,"web":[{"name":"Raleway","file":"https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap"}]},
    },{
      plugin: require('../node_modules/exo-plugin-next-seo/exo-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"https://www.autogenius.io/","site_name":"Auto Genius"}},
    },{
      plugin: require('../exo-browser.js'),
      options: {"plugins":[]},
    }]
